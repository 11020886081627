import React from 'react';

var Highcharts = require('highcharts');


class SessionGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            graph_results: [],
            chartID: props.metadata.mainsessionid + "_" + props.metadata.learningstrategy
        }
    }


    componentDidUpdate() {
        // Plot Alpha
        // ==============
        // once the render is called and the component mounts let's place the charts
        var data = [{
            name: "alpha",
            color: '#000000',
            data: this.state.graph_results.alpha // 0 is alpha
        }]

        this.createChart(this.state.chartID + "_alpha", "Alpha", data)

        // Plot Beta
        // ==============
        // once the render is called and the component mounts let's place the charts
        data = [{
            name: "beta",
            color: '#ff0000',
            data: this.state.graph_results.beta // 0 is alpha
        }]

        this.createChart(this.state.chartID + "_beta", "Beta", data)

        // Plot Gamma
        // ==============
        // once the render is called and the component mounts let's place the charts
        data = [{
            name: "gamma",
            color: '#00ff00',
            data: this.state.graph_results.gamma // 0 is alpha
        }]

        this.createChart(this.state.chartID + "_gamma", "Gamma", data)
    }

    componentDidMount() {
        const graphReqParams = {
            graphdata: this.props.metadata
        };

        // send neurorythm data request
        fetch(this.props.server + "/data/new", {
            'method': 'post',
            body: JSON.stringify(graphReqParams),
            headers: new Headers({
                'Content-Type': 'application/json; charset=utf-8'
            })
        })
            .then(result => result.json())
            .then(items => {
                this.setState({
                    graph_results: items
                })
            }).catch(error => console.log(error))


    }

    createChart = (rootDomElementId, title, dataInput) => {

        //var domElementId = title;

        //var check = document.getElementById(domElementId);
        //if (check) {
        // remove if exists
        //    document.getElementById(rootDomElementId).removeChild(check);
        //}

        //var elem = document.createElement('div');
        //elem.id=domElementId;
        //document.getElementById(rootDomElementId).appendChild(elem);
        try {
            new Highcharts.Chart({
                chart: {
                    type: 'spline',
                    renderTo: rootDomElementId,
                    animation: false,
                    marginRight: 10,
                    zoomType: 'x'
                },
                title: {
                    text: title
                },
                xAxis: {
                    type: 'datetime',
                    tickPixelInterval: 150,
                    labels: {
                        formatter: function () {
                            // Convert seconds to a time format (HH:MM:SS)
                            let totalSeconds = this.value / 100;
                            let hours = Math.floor(totalSeconds / 3600);
                            let minutes = Math.floor((totalSeconds % 3600) / 60);
                            let seconds = totalSeconds % 60;

                            return Highcharts.dateFormat('%H:%M:%S', Date.UTC(1970, 0, 1, hours, minutes, seconds));
                        }
                    },
                },
                yAxis: {

                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    } /*,
	              plotLines: [{
	                          value: 0,
	                          width: 5,
	                          color: '#990000'
	                          },
	                          {
	                          value: 1,
	                          width: 5,
	                          color: '#990000'
	                          }]*/
                },
                tooltip: {
                    formatter: function () {
                        // Display only time in the tooltip
                        let totalSeconds = this.x / 100;
                        let hours = Math.floor(totalSeconds / 3600);
                        let minutes = Math.floor((totalSeconds % 3600) / 60);
                        let seconds = totalSeconds % 60;

                        return '<b>' + Highcharts.dateFormat('%H:%M:%S', Date.UTC(1970, 0, 1, hours, minutes, seconds)) + '</b>: ' + this.y;
                    }
                },
                legend: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                series: dataInput
            });
        }
        catch (error) {
            console.log("Highchart error", error)
        }

    }




    render() {

        if (!this.state.graph_results) {
            return false
        }

        if (!this.state.graph_results.alpha) {
            return false
        }

        return (
            <div className="well well-lg">

                <div id={this.state.chartID + "_alpha"}></div>

                <div id={this.state.chartID + "_beta"}></div>

                <div id={this.state.chartID + "_gamma"}></div>
            </div>
        )
    }

}


export default SessionGraph;







