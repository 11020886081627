import React from 'react';
import './App.css';
import Sessions from './sessions';
import OptionsBar from './optionsbar';
import history from './history';


class SessionListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        // force an update if the URL changes
        history.listen(() => this.forceUpdate());
    }

    render() {

        return (
            <div className="container-fluid">
                <Sessions server={process.env.REACT_APP_SERVER}/>
            </div>
        );
    }
}

class App extends React.Component {

    render() {
        return (
            <div>
                <OptionsBar/>
                <hr/>
                <SessionListContainer/>
            </div>
        )
    }
}

export default App;