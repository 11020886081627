import React from 'react';

class CompositeSummary extends React.Component {


    /** Given input data (composite or neurorythm, calculate euclidean distances) */
    calculateEuclideanDistance = (array1, array2) => {
        return Math.sqrt(Math.pow(array1[0] - array2[0], 2) +
            Math.pow(array1[1] - array2[1], 2) +
            Math.pow(array1[2] - array2[2], 2)).toFixed(2)
    }

    componentDidMount() {
        var metadata = {
            mainsessionid: this.props.metadata.mainsessionid,
            username: this.props.metadata.username
        };
        const reqParams = {
            neurorythm: metadata
        };

        // send neurorythm data request
        fetch(this.props.server + "/data/new", {
            'method': 'post',
            body: JSON.stringify(reqParams),
            headers: new Headers({
                'Content-Type': 'application/json; charset=utf-8'
            })
        })
            .then(result => result.json())
            .then(items => {
                if (items.composite_data === undefined) {
                    this.setState({
                        error: "Sorry no data is yet available for this session. Maybe the analysis is not complete yet?"
                    });
                } else {
                    this.setState({
                        error: undefined,
                        results: items,

                        test_compositedata: items.composite_data.filter((item) => {
                            return item.ls === "test"
                        })[0]
                    });
                }

            }).catch(error => console.log(error))


    }

    render() {

        const calculateEuclideanDistance = this.calculateEuclideanDistance;

        var composite_data
        if (!this.state) {
            return false
        }
        if (!this.state.results) {
            return false
        }

        const results = this.state.results;

        const beststrategy_composite = results.beststrategy_composite
        const test_compositeData = this.state.test_compositedata.composite_data.map((item) => {
            return 1 * (item.replace("%", ""))
        })

        const wavesStrength = {
            alpha: 0,
            beta: 0,
            gamma: 0,
        };

        composite_data = results.composite_data.map(function (result, index) {
            var trClass = ""
            const resultData = result["composite_data"].map((item) => {
                return 1 * (item.replace("%", ""))
            })

            if (result.ls !== 'test') {
                wavesStrength.alpha += result.composite_data[0];
                wavesStrength.beta += result.composite_data[1];
                wavesStrength.gamma += result.composite_data[2];
            }

            return <tr className={trClass} key={index}>
                <td>{result["ls"]}</td>
                <td>{result["composite_data"][0]}</td>
                <td>{result["composite_data"][1]}</td>
                <td>{result["composite_data"][2]}</td>
                <td>{calculateEuclideanDistance(resultData, test_compositeData)}</td>
            </tr>
        });

        let dominantWave = Object.keys(wavesStrength).reduce((a, b) =>
            wavesStrength[a] > wavesStrength[b] ? a : b
        );

        return (
            <div>

                <h5 className="display-5">Composite Data Analysis Summary</h5>
                <div>
                    <dl>
                        <dt>Dominant wave was <span className="badge badge-success">
                            {dominantWave}</span></dt>
                    </dl>
                </div>

                <div >
                    <table className="reportTable table table-condensed table-responsive">
                        <thead>
                            <tr>
                                <th>Plot</th><th>Alpha (5-15Hz)</th>
                                <th>Beta (16-25Hz)</th>
                                <th>Gamma (26-35Hz)</th>
                                <th>Euclidean distance from test</th>
                            </tr>
                        </thead>
                        <tbody>
                            {composite_data}
                        </tbody>
                    </table>
                </div>

            </div>
        )

    }

}

export default CompositeSummary;