import React from 'react';
import SessionGraph from './sessiongraph';

class SessionComposite extends React.Component {


    /** Given input data (composite or neurorythm, calculate euclidean distances) */
    calculateEuclideanDistance = (array1, array2) => {
        return Math.sqrt(Math.pow(array1[0] - array2[0], 2) +
            Math.pow(array1[1] - array2[1], 2) +
            Math.pow(array1[2] - array2[2], 2)).toFixed(2)
    }


    render() {

        const calculateEuclideanDistance = this.calculateEuclideanDistance;

        var composite_data
        if (!this.props.composite_data) {
            return false
        }


        const beststrategy_composite = this.props.beststrategy_composite
        const test_compositeData = this.props.test_compositedata.composite_data.map((item) => {
            return 1 * (item.replace("%", ""))
        })

        composite_data = this.props.composite_data.map(function(result, index) {
            var trClass = ""
            if (result.ls === beststrategy_composite) {
                trClass = "table-success"
            }
            const resultData = result["composite_data"].map((item) => {
                return 1 * (item.replace("%", ""))
            })
            return <tr className={trClass} key={index}>
                        <td>{result["ls"]}</td>
                        <td>{result["composite_data"][0]}</td>
                        <td>{result["composite_data"][1]}</td>
                        <td>{result["composite_data"][2]}</td>
                        <td>{calculateEuclideanDistance(resultData, test_compositeData)}</td>
                      </tr>
        });


        return (
            <div>

              <div className="panel panel-danger">
                  <div className="panel-header">
                      <h3 className="text-center">Composite brain data Analysis</h3>
                  </div>
                  <div className="panel-body">
                    { /* Graphs */ }
                    <SessionGraph server={this.props.server}  metadata={this.props.metadata} />

                    { /*<div className="well well-lg">
                      <table className="table table-condensed">
                        <thead>
                        <tr>
                        <th>Plot</th><th>Alpha (5-15Hz)</th>
                        <th>Beta (16-25Hz)</th>
                        <th>Gamma (26-35Hz)</th>
                        <th>Euclidean distance from test</th>
                        </tr>
                        </thead>
                        <tbody>
                        {composite_data}
                        </tbody>
                      </table>

                    </div>*/ }

                  </div>
                  { /*<div className="panel-footer">
                    <dl>
                    <dt>Best Learning Strategy was <span className="badge badge-success">   {this.props.beststrategy_composite}</span></dt> 
                    </dl>
                  </div>*/ }
                </div>


            </div>
        )

    }

}

export default SessionComposite;