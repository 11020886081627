import React from 'react';

var Highcharts = require('highcharts');


class SessionNeuroGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            graph_results: [],
            chartID: "neuro" + "_" + props.metadata.mainsessionid + "_" + props.metadata.learningstrategy
        }
    }


    componentDidUpdate() {

        var rawGraphSeries = this.state.graph_results.rawData || [];
        // var filteredGraphSeries = (this.state.graph_results.filteredData) || [];
        var calculatedSMASeries = this.calculateSMA((this.state.graph_results.rawData) || {}, 10);

        // Plot Alpha
        // ==============
        // once the render is called and the component mounts let's place the charts
        var data = [{
            name: "alpha",
            color: '#000000',
            data: rawGraphSeries['alpha'], // 0 is alpha,
            marker: {
                radius: 6
            },
            pointInterval: 15
            // }, {
            //     name: "alpha (fitted)",
            //     color: '#327486',
            //     data: filteredGraphSeries['alpha'], // 0 is alpha,
        }, {
            name: "alpha average",
            color: '#327486',
            data: calculatedSMASeries['alpha'], // 0 is alpha,
        }]

        this.createChart(this.state.chartID + "_alpha", "Alpha", data)

        // Plot Beta
        // ==============
        // once the render is called and the component mounts let's place the charts
        data = [{
            name: "beta",
            color: '#ff0000',
            data: rawGraphSeries['beta'], // 0 is alpha,
            marker: {
                radius: 6
            },
            pointInterval: 15
            // }, {
            //     name: "beta (fitted)",
            //     color: '#027886',
            //     data: filteredGraphSeries['beta'] // 0 is alpha
        }, {
            name: "beta average",
            color: '#027886',
            data: calculatedSMASeries['beta'], // 0 is alpha,
        }]

        this.createChart(this.state.chartID + "_beta", "Beta", data)

        // Plot Gamma
        // ==============
        // once the render is called and the component mounts let's place the charts
        data = [{
            name: "gamma",
            color: '#00ff00',
            data: rawGraphSeries['gamma'], // 0 is alpha,
            marker: {
                radius: 6
            },
            pointInterval: 15
            // }, {
            //     name: "gamma (fitted)",
            //     color: '#323086',
            //     data: filteredGraphSeries['gamma'] // 0 is alpha
        }, {
            name: "gamma average",
            color: '#027886',
            data: calculatedSMASeries['gamma'], // 0 is alpha,
        }]

        this.createChart(this.state.chartID + "_gamma", "Gamma", data)
    }

    componentDidMount() {
        const graphReqParams = {
            neurographdata: this.props.metadata
        };

        // send neurorythm data request
        fetch(this.props.server + "/data/new", {
            'method': 'post',
            body: JSON.stringify(graphReqParams),
            headers: new Headers({
                'Content-Type': 'application/json; charset=utf-8'
            })
        })
            .then(result => result.json())
            .then(items => {
                this.setState({
                    graph_results: items
                })
            }).catch(error => console.log("error", error.message))


    }

    createChart = (rootDomElementId, title, dataInput, _dashStyle) => {

        var dashStyle = _dashStyle || 'Solid';
        //var domElementId = title;

        //var check = document.getElementById(domElementId);
        //if (check) {
        // remove if exists
        //    document.getElementById(rootDomElementId).removeChild(check);
        //}

        //var elem = document.createElement('div');
        //elem.id=domElementId;
        //document.getElementById(rootDomElementId).appendChild(elem);
        try {
            new Highcharts.Chart({
                chart: {
                    type: 'spline',
                    renderTo: rootDomElementId,
                    animation: false,
                    marginRight: 10,
                    zoomType: 'x',
                    borderRadius: 5,
                    borderWidth: 2,
                    borderColor: '#888888'
                },
                title: {
                    text: title
                },
                xAxis: {
                    type: 'datetime',
                    tickPixelInterval: 150,
                    labels: {
                        formatter: function () {
                            // Convert seconds to a time format (HH:MM:SS)
                            let totalSeconds = this.value / 100;
                            let hours = Math.floor(totalSeconds / 3600);
                            let minutes = Math.floor((totalSeconds % 3600) / 60);
                            let seconds = totalSeconds % 60;

                            return Highcharts.dateFormat('%H:%M:%S', Date.UTC(1970, 0, 1, hours, minutes, seconds));
                        }
                    },
                },
                yAxis: {

                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    } /*,
                  plotLines: [{
                              value: 0,
                              width: 5,
                              color: '#990000'
                              },
                              {
                              value: 1,
                              width: 5,
                              color: '#990000'
                              }]*/
                },
                tooltip: {
                    formatter: function () {
                        // Display only time in the tooltip
                        let totalSeconds = this.x / 100;
                        let hours = Math.floor(totalSeconds / 3600);
                        let minutes = Math.floor((totalSeconds % 3600) / 60);
                        let seconds = totalSeconds % 60;

                        return '<b>' + Highcharts.dateFormat('%H:%M:%S', Date.UTC(1970, 0, 1, hours, minutes, seconds)) + '</b>: ' + this.y;
                    }
                },
                legend: {
                    enabled: true
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                series: dataInput
            });
        } catch (err) {
            console.log("Error in highcharts", err)
        }

    }


    calculateSMA = (waveData, windowSize) => {
        let smaResult = {};
        for (let wave in waveData) {
            let data = waveData[wave];
            let sma = [];
            for (let i = 0; i <= data.length - windowSize; i++) {
                let sum = 0;
                for (let j = 0; j < windowSize; j++) {
                    sum += data[i + j][1]; // Accessing the second element in each sub-array
                }
                let average = sum / windowSize;
                let time = data[i + windowSize - 1][0]; // Use the time value at the end of the window
                sma.push([time, average]);
            }
            smaResult[wave] = sma;
        }
        return smaResult;
    };



    render() {

        if (!this.state.graph_results) {
            return false
        }

        if (!this.state.graph_results.rawData) {
            return false
        }

        return (
            <div className="neurorythmcharts well well-lg">

                <div id={this.state.chartID + "_alpha"}></div>
                <br />

                <div id={this.state.chartID + "_beta"}></div>
                <br />

                <div id={this.state.chartID + "_gamma"}></div>
            </div>
        )
    }

}


export default SessionNeuroGraph;







