import React from 'react';

import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

const urlPropsQueryConfig = {
    appmode: {
        type: UrlQueryParamTypes.string
    },
};

class FooterContent extends React.Component {


    render() {
        {
            /* don't show this if in app mode used by app to embed this as a webview*/
        }
        if (this.props.appmode) {
            return null;
        }

        return (
            <div>
            
                  <section id="detailsofhowitworkscontent">
                        <div className="container">
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <div className="p-5">
                                <img className="img-fluid rounded-circle" src="https://static1.squarespace.com/static/53eb8591e4b0ba68f27eea55/t/58920750197aea16e636e8c1/1485965148120/Curtis_Lecture_Halls_interior_view3_empty_class.jpg?format=2500w" alt="" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="p-5">
                                <p>Details of how it works</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <footer className="py-5 bg-dark" id="footercontent">
                        <div className="container">
                          <p className="m-0 text-center text-white">Copyright © IntellAdapt 2017</p>
                        </div>
                      </footer>

            </div>
        );
    }

}


export default addUrlProps({
    urlPropsQueryConfig
})(FooterContent);



