import React from "react";
import SessionNeuroGraph from "./sessionneurograph";

class SessionNeurorythm extends React.Component {
    /** Given input data (composite or neurorythm, calculate euclidean distances) */
    calculateEuclideanDistance = (array1, array2) => {
        return Math.sqrt(
            Math.pow(array1[0] - array2[0], 2) +
                Math.pow(array1[1] - array2[1], 2) +
                Math.pow(array1[2] - array2[2], 2)
        ).toFixed(2);
    };

    formatFreqData(data) {
        return data.length > 2 ? (
            <div className="form-group">
                {data.map((item) => (
                    <span
                        key={item}
                        style={{
                            width: "33%",
                            paddingLeft: "5px",
                        }}
                    >
                        {typeof item === "number" ? item.toFixed(2) : item}
                    </span>
                ))}
            </div>
        ) : (
            ""
        );
    }

    render() {
        const beststrategy = this.props.neurodata.beststrategy;
        const formatFreqData = this.formatFreqData;
        const calculateEuclideanDistance = this.calculateEuclideanDistance;
        const testdata = this.props.testdata;

        let results = this.props.neurodata.freqdata.map(function (
            result,
            index
        ) {
            var trClass = "";
            if (result.ls === beststrategy) {
                trClass = "table-success";
            }
            return (
                <tr className={trClass} key={index}>
                    <td>{result.sessionName}</td>
                    <td>{result.ls}</td>
                    <td>{formatFreqData(result.d)}</td>
                    <td>
                        <p className="text-right">
                            {calculateEuclideanDistance(result.d, testdata.d)}
                        </p>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <div className="panel panel-info">
                    <div className="panel-header">
                        <h3 className="text-center">
                            Neurorythm brain data Analysis
                        </h3>
                        <hr />
                    </div>
                    <div className="panel-body">
                        <SessionNeuroGraph
                            server={this.props.server}
                            metadata={this.props.metadata}
                        />

                        {/*<table className="table table-condensed">
            <thead><tr><th>SessionName</th><th>Learning Strategy</th><th>Frequencies</th>
            <th><p className="text-right">Euclidean distance from Test</p></th></tr></thead>
            <tbody>
            {results}
            </tbody>
          </table>   */}
                    </div>
                    {/*<div className="panel-footer">
          <dl>
          <dt>Best Learning Strategy was <span className="badge badge-success">   {this.props.neurodata.beststrategy}</span></dt>
          </dl>
        </div>*/}
                </div>
            </div>
        );
    }
}

export default SessionNeurorythm;
