// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.intelladaptTransparentLogo {
  width: 28%;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

body {
  padding: 5px;
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
}

a {
  color: #589636;
}

.json {
  font: 12px "Lucida Console", Menlo, monospace, Consolas;
}

.errorMessage {
  font-weight: bold;
  color: red;
}

.warningMessage {
  color: brown;
}
.successMessage {
  color: green;
}

.fullscreen {
    z-index: 9999; 
    width: 100%; 
    height: 100%; 
    position: fixed; 
    top: 0; 
    left: 0; 
 }


 .reportTable {
   font-size: .85em;
 }`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,4CAA4C;EAC5C,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,OAAO,uBAAuB,EAAE;EAChC,KAAK,yBAAyB,EAAE;AAClC;;AAEA;EACE,YAAY;EACZ,wDAAwD;AAC1D;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;CACV;;;CAGA;GACE,gBAAgB;CAClB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.intelladaptTransparentLogo {\n  width: 28%;\n  height: 100%;\n}\n\n.App-logo {\n  animation: App-logo-spin infinite 20s linear;\n  height: 80px;\n}\n\n.App-header {\n  background-color: #222;\n  height: 150px;\n  padding: 20px;\n  color: white;\n}\n\n.App-intro {\n  font-size: large;\n}\n\n@keyframes App-logo-spin {\n  from { transform: rotate(0deg); }\n  to { transform: rotate(360deg); }\n}\n\nbody {\n  padding: 5px;\n  font: 14px \"Lucida Grande\", Helvetica, Arial, sans-serif;\n}\n\na {\n  color: #589636;\n}\n\n.json {\n  font: 12px \"Lucida Console\", Menlo, monospace, Consolas;\n}\n\n.errorMessage {\n  font-weight: bold;\n  color: red;\n}\n\n.warningMessage {\n  color: brown;\n}\n.successMessage {\n  color: green;\n}\n\n.fullscreen {\n    z-index: 9999; \n    width: 100%; \n    height: 100%; \n    position: fixed; \n    top: 0; \n    left: 0; \n }\n\n\n .reportTable {\n   font-size: .85em;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
