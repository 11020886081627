import React from 'react';
import SessionDetail from './sessiondetail';

class Session extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clicked: false
        };

    }

    // This syntax ensures `this` is bound within showSession.
    showSession = (e) => {
        e.preventDefault();
        // just toggle clicked state and let React re-render detail component
        this.setState({
            clicked: !this.state.clicked
        });
    }

    detailElement = () => {
        const session = this.props.session;
        const metadata = session.metadata;
        return (
            <div><SessionDetail server={this.props.server}
            key={session._id}
            metadata={metadata}
            /> { /*                   username={metadata.username}
                      mainsessionid={metadata.mainsessionid}
                      learningstrategy={metadata.learningstrategy}
                        */ }
                 </div>
        )
    }

    render() {
        const metadata = this.props.session.metadata;

        return (
            <div className="justify-content-between">
                
                  <div className="page-header">
                  <h5>{metadata.sessionName} <small>{metadata.learningstrategy}</small></h5>
                  </div>
                  { /*<p className="text-right"><i className="fa fa-chevron-down pull-right" aria-hidden="true"></i></p> */ }

                  { /* show the detail info component here if the button was clicked 
                  {this.state.clicked ? this.detailElement() : null}*/ }

                  { this.detailElement()}

            </div>

        )
    }

}


export default Session;

