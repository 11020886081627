
import React from 'react';

import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

const urlPropsQueryConfig = {
    appmode: {
        type: UrlQueryParamTypes.string
    },
};

class NavBar extends React.Component {


    render() {
        {
            /* don't show this if in app mode used by app to embed this as a webview*/
        }
        if (this.props.appmode) {
            return null;
        }

        return (
            <div>
            <nav id="navbarcontent" className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
	    		<div className="container">
		        <a className="navbar-brand" href="#">IntellAdapt Brainiak</a>
		        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
		          <span className="navbar-toggler-icon"></span>
		        </button>
		        <div className="collapse navbar-collapse" id="navbarResponsive">
		          <ul className="navbar-nav ml-auto">
		            <li className="nav-item active">
		              <a className="nav-link" href="#">Home
		                <span className="sr-only">(current)</span>
		              </a>
		            </li>
		            <li className="nav-item">
		              <a className="nav-link" href="#">About</a>
		            </li>
		            <li className="nav-item">
		              <a className="nav-link" href="#">Contact</a>
		            </li>
		          </ul>
		        </div>
		      </div>
	      </nav>


				<header className="masthead">
			      <div className="overlay">
			        <div className="container">
			          <img src="http://static1.squarespace.com/static/53eb8591e4b0ba68f27eea55/t/584eed268419c2a296e19b06/1502392415341/?format=300w" className="img-responsive intelladaptTransparentLogo" />
			          <h3 className="display-5 text-white">Brainiak</h3>
			          <h5 className="text-white">IntellAdapt's groundbreaking learning platform</h5>
			        </div>
			      </div>
			    </header>
			</div>
        );
    }

}


export default addUrlProps({
    urlPropsQueryConfig
})(NavBar);



