import React from 'react';

class OptionsBar extends React.Component {

    // This syntax ensures `this` is bound within showSession.
    printpage = (e) => {
        e.preventDefault();
        window.print();
    }

    indexpage = (e) => {
        e.preventDefault();
        window.location.href = "/";
    }

    render() {
        return (

            <nav className="navbar navbar-right">
			  <div className="container-fluid">
			    <div className="navbar-header">
			      <a className="navbar-brand" href="#">
			        <div className="btn-group" role="group" aria-label="...">
					  <button onClick={this.indexpage} type="button" className="btn btn-default">Show all</button>
					  <button onClick={this.printpage} type="button" className="btn btn-default">Print</button>					  
					  
					</div>
			      </a>
			    </div>
			  </div>
			</nav>


        )
    }

}


export default OptionsBar;

