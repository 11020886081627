import React from 'react';
import SessionNeurorythm from './session-neurorythm';
import SessionComposite from './session-composite';


class SessionDetail extends React.Component {

    constructor(props) {
        super(props);
        // error: undefined means we are good and we have data
        this.state = {
            results: [],
            error: undefined
        }
    }


    componentDidMount() {
        var metadata = {
            mainsessionid: this.props.metadata.mainsessionid,
            username: this.props.metadata.username
        };
        console.log("Sending neurorythm request with : " + JSON.stringify(metadata));
        const reqParams = {
            neurorythm: metadata
        };

        // send neurorythm data request
        fetch(this.props.server + "/data/new", {
            'method': 'post',
            body: JSON.stringify(reqParams),
            headers: new Headers({
                'Content-Type': 'application/json; charset=utf-8'
            })
        })
            .then(result => result.json())
            .then(items => {
                if (items.freqdata === undefined) {
                    this.setState({
                        error: "Sorry no data is yet available for this session. Maybe the analysis is not complete yet?"
                    });
                } else {
                    this.setState({
                        error: undefined,
                        results: items,
                        test_freqdata: items.freqdata.filter((item) => {
                            return item.ls === "test"
                        })[0],
                        test_compositedata: items.composite_data.filter((item) => {
                            return item.ls === "test"
                        })[0]
                    });
                }

            }).catch(error=>console.log(error))


    }




    render() {

        if (this.state.error) {
            return (
                <div className="alert alert-danger">
                {this.state.error}
                </div>
            )
        }

        if (!this.state.results.freqdata) {
            return false
        }


        return (
            <div className="container-fluid">
                  <div className="well well-lg">
                  <hr/> 
                  
                   <SessionComposite
            server={this.props.server}
            metadata={this.props.metadata}
            composite_data={this.state.results.composite_data}
            beststrategy_composite={this.state.results.beststrategy_composite}
            test_compositedata={this.state.test_compositedata}
            />

                    
            
                   { /* Neurorythm data */ }
                   <SessionNeurorythm
            server={this.props.server}
            metadata={this.props.metadata}
            neurodata={this.state.results}
            testdata={this.state.test_freqdata} />


                  </div>
            </div>
        )
    }

}


export default SessionDetail;

