import React from 'react';

class SessionRecommendation extends React.Component {
    componentDidUpdate() {}
    componentDidMount() {}

    render() {
        return (
            <div className = "well well-lg">
                <p>Based on the analysis, our recommendation for you is</p>
                <div className="row">
                <div className="col-xs-9">{this.props.recoText}</div>
                </div>
            </div>
        )
    }
}

export default SessionRecommendation;







