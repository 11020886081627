import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import NavBar from "./navbar";
import FooterContent from "./footercontent";
import { configureUrlQuery } from "react-url-query";
import history from "./history";

window.process = {};

// link the history used in our app to url-query so it can update the URL with it.
configureUrlQuery({
    history,
});

const rootElement = document.getElementById("root");
const navbarElement = document.getElementById("navbarcontent");

createRoot(rootElement).render(<App />);
createRoot(navbarElement).render(<NavBar />);
