import React from "react";
import SessionRecommendation from "./session-recommendation";

class NeuroSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recoText: {
                Apprentice: `Apprentice Learning: The linear presentation of concept to define a procedural learning is dominant in
                                this learning strategy. Following recommendation are provided for learners with apprentice learning
                                strategy:
                    1. Please use content from the books that provide you step-by- step procedure
                    2. The steps need to have examples and exposition
                    3. Create a procedural chart for the concept mastery
                    4. Repeat process for each sub-concept with practice test feedback offered by IntellADAPTs
                    adaptive course`,
                Incidental: `Incidental Learning:  The presentation of concept with real-life scenario or case-studies is dominant in the learning strategy. Following recommendation are provided for learners with incidental learning strategy:
                    1.  First use the video of the case study or exposition of the concept
                    2.  Understand each learning scenario to master sub-concepts
                    3.  Study case-study to find relevance of the content with your real life experience
                    4.  Repeat process for each sub-concept with practice test feedback offered by IntellADAPTs adaptive course
                    `,
                Inductive: `Inductive Learning:  The solved example for a given concept exposition is dominant content in this learning strategy. Solving many examples leads to understanding of underlying principal. Following recommendation are provided for learners with inductive learning strategy:
                    1.  Find many solved examples for the concept understanding
                    2.  Divide each concept into sub-concepts and use example for each part
                    3.  Connect all parts to understand the entire concept
                    4.  Repeat process for each sub-concept with practice test feedback offered by IntellADAPTs adaptive course
                    `,
                Deductive: `Deductive Learning: The problem is divided into hints that you use to understand concept by doing an interactive fashion. The principal is exposed first and you understand concept by applying the principal in may interactive exercises. Following recommendation are provided for learners with deductive learning strategy:
                    1.  First read the concept principal carefully and evaluate each hint
                    2.  Use your principal (equation) to solve each step based on hints and choices
                    3.  Combine each interactive step to understand the application of entire principal
                    4.  Repeat process for each sub-concept with practice test feedback offered by IntellADAPTs adaptive course
                    `,
                Discovery: `Discovery Learning: The concept is presented in the form puzzles, games and animated presentations. Solving the puzzle will lead you to learn the inherent principal. Following recommendation are provided for learners with discovery learning strategy:
                    1.  First read the concept and/or the principal for the puzzle
                    2.  Use the drag-and-drop or game elements to reach the desired goal
                    3.  Solve puzzles for each sub-concept and then put all elements together for understanding the inherent concept.
                    4.  Repeat process for each sub-concept with practice test feedback offered by IntellADAPTs adaptive course
                    `,
            },
        };
    }

    /** Given input data (composite or neurorythm, calculate euclidean distances) */
    calculateEuclideanDistance = (array1, array2) => {
        return Math.sqrt(
            Math.pow(array1[0] - array2[0], 2) +
                Math.pow(array1[1] - array2[1], 2) +
                Math.pow(array1[2] - array2[2], 2)
        ).toFixed(2);
    };

    formatFreqData(data) {
        return data.length > 2 ? (
            <div className="form-group">
                {data.map((item) => (
                    <span
                        key={item}
                        style={{
                            width: "33%",
                            paddingLeft: "5px",
                        }}
                    >
                        {typeof item === "number" ? item.toFixed(2) : item}
                    </span>
                ))}
            </div>
        ) : (
            ""
        );
    }

    componentDidMount() {
        var metadata = {
            mainsessionid: this.props.metadata.mainsessionid,
            username: this.props.metadata.username,
        };
        const reqParams = {
            neurorythm: metadata,
        };

        // send neurorythm data request
        fetch(this.props.server + "/data/new", {
            method: "post",
            body: JSON.stringify(reqParams),
            headers: new Headers({
                "Content-Type": "application/json; charset=utf-8",
            }),
        })
            .then((result) => result.json())
            .then((items) => {
                if (items.freqdata === undefined) {
                    this.setState({
                        error: "Sorry no data is yet available for this session. Maybe the analysis is not complete yet?",
                    });
                } else {
                    this.setState({
                        error: undefined,
                        results: items,
                        test_freqdata: items.freqdata.filter((item) => {
                            return item.ls === "test";
                        })[0],
                    });
                }
            })
            .catch((error) => console.log(error));
    }

    render() {
        if (!this.state) {
            return false;
        }
        if (!this.state.results) {
            return false;
        }

        const results = this.state.results;

        const beststrategy = results.beststrategy;
        const formatFreqData = this.formatFreqData;
        const calculateEuclideanDistance = this.calculateEuclideanDistance;
        const testdata = this.state.test_freqdata;

        let resultsToRender = results.freqdata.map(function (result, index) {
            var trClass = "";
            if (result.ls === beststrategy) {
                trClass = "table-success";
            }
            return (
                <tr className={trClass} key={index}>
                    <td>{result.sessionName}</td>
                    <td>{result.ls}</td>
                    <td>{formatFreqData(result.d)}</td>
                    <td>
                        <p className="text-right">
                            {calculateEuclideanDistance(result.d, testdata.d)}
                        </p>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <h5 className="display-5">NeuroRythm Analysis Summary</h5>

                <div className="panel-footer">
                    <dl>
                        <dt>
                            Best Learning Strategy was{" "}
                            <span className="badge badge-success">
                                {" "}
                                {results.beststrategy}
                            </span>
                        </dt>
                    </dl>
                </div>

                <div>
                    <table className="reportTable table table-condensed table-responsive">
                        <thead>
                            <tr>
                                <th>SessionName</th>
                                <th>Learning Strategy</th>
                                <th>Frequencies</th>
                                <th>
                                    <p className="text-right">
                                        Euclidean distance
                                    </p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>{resultsToRender}</tbody>
                    </table>
                </div>

                <hr />

                <SessionRecommendation
                    recoText={
                        this.state.recoText[this.state.results.beststrategy]
                    }
                />
            </div>
        );
    }
}

export default NeuroSummary;
