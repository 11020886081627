import React from "react";
import SessionItem from "./session";
import CompositeSummary from "./composite-summary";
import NeuroSummary from "./neuro-summary";

import { addUrlProps, UrlQueryParamTypes } from "react-url-query";

const urlPropsQueryConfig = {
    mainsessionid: {
        type: UrlQueryParamTypes.string,
    },
};

const formatDateTime = (dateTimeString) => {
    if (dateTimeString) {
        let dateTime = new Date(dateTimeString);
        let options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
        return dateTime.toLocaleDateString('en-US', options);
    } else {
        return 'N/A';
    }
}

class Sessions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        fetch(this.props.server + "/session" + this.mainSessionId())
            .then((result) => result.json())
            .then((items) =>
                this.setState({
                    items,
                })
            )
            .catch((err) => console.log(err));
    }

    /** Returns '/'+mainSessionid value from URl params if found.
        If ?mainsessionid was not provided return empty string
        Note that mainsessionid can also be a string of ids, or even a json array of mainsessionid objects
    */
    mainSessionId() {
        const { mainsessionid } = this.props;

        if (mainsessionid !== undefined) {
            return "/" + mainsessionid;
        }
        return "";
    }

    render() {
        var sessions = this.state.items;

        const { mainsessionid } = this.props;

        // let's group the session items by the mainSessionId
        // so that we see all sssions under one mainSessionId under one collapsible section

        var sessionsGroupedByMainId = new Map();

        sessions.map((session) => {
            let key = session.metadata.mainsessionid;
            if (key === undefined) return null;

            if (sessionsGroupedByMainId.has(key)) {
                sessionsGroupedByMainId.get(key).push(session);
            } else {
                sessionsGroupedByMainId.set(key, [session]);
            }
        });

        // avoid rendering if empty data
        if (sessionsGroupedByMainId.size < 1) return null;

        const showall = mainsessionid === undefined;

        const mainSessionIdSections = [...sessionsGroupedByMainId.keys()].map(
            (mainsessionid) => {
                return (
                    <GroupOfSessions
                        showall={showall}
                        key={mainsessionid}
                        server={this.props.server}
                        mainsessionid={mainsessionid}
                        sessions={sessionsGroupedByMainId.get(mainsessionid)}
                    />
                );
            }
        );

        return <div>{mainSessionIdSections}</div>;
    }
}
{
    /*Component for one session with a mainSessionId Section*/
}
class GroupOfSessions extends React.Component {
    constructor(props) {
        super(props);
        {
            /*Each session has the same property so use the first one*/
        }
        this.state = {
            metadata: this.props.sessions[0].metadata,
            username: this.props.sessions[0].metadata.username,
            sessionName: this.props.sessions[0].metadata.sessionName,
            createdAt: this.props.sessions[0].createdAt,
            appendixClicked: false,
        };
    }

    showAppendix = (e) => {
        e.preventDefault();
        // just toggle clicked state and let React re-render appendix component
        this.setState({
            appendixClicked: !this.state.appendixClicked,
        });
    };

    render() {
        const sessionitems = this.props.sessions.map((session) => {
            return (
                <div key={session._id}>
                    <SessionItem
                        key={session._id}
                        session={session}
                        server={this.props.server}
                    />
                    <hr />
                </div>
            );
        });
        const itemid = "collapsed_" + this.props.mainsessionid;

        var contentIfNeeded = null;
        if (this.props.showall === false) {
            contentIfNeeded = (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div id={itemid}>
                                <ul className="list-group">
                                    <li className="list-group-item">
                                        <CompositeSummary
                                            metadata={this.state.metadata}
                                            server={this.props.server}
                                        />
                                    </li>
                                    <li className="list-group-item">
                                        <NeuroSummary
                                            metadata={this.state.metadata}
                                            server={this.props.server}
                                        />
                                    </li>

                                    <li className="list-group-item">
                                        <span className="rounded">
                                            <a
                                                href="#"
                                                onClick={this.showAppendix}
                                            >
                                                Session Appendix/Charts
                                                <p className="text-right">
                                                    <i
                                                        className="fa fa-chevron-down pull-right"
                                                        aria-hidden="true"
                                                    ></i>
                                                </p>
                                            </a>
                                        </span>
                                        <span className="rounded">
                                            {this.state.appendixClicked
                                                ? sessionitems
                                                : null}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        const sessionLinkShowAllMode = (
            <div>
                <a
                    role="button"
                    href={"/session/?mainsessionid=" + this.props.mainsessionid}
                >
                    {this.state.username} | {this.state.sessionName} | {formatDateTime(this.state.createdAt)}
                </a>
            </div>
        );

        const sessionLinkSingleSessionMode = (
            <div className="page-header">
                <h3>
                    {this.state.username} | {this.state.sessionName}
                </h3>
            </div>
        );

        return (
            <div>
                {this.props.showall == true
                    ? sessionLinkShowAllMode
                    : sessionLinkSingleSessionMode}

                {contentIfNeeded}

                <br />
            </div>
        );
    }
}

export default addUrlProps({
    urlPropsQueryConfig,
})(Sessions);
